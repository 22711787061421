<template>
    <b-button
        class="ml-1"
        @click="clickBack"
    >
        Cancel
    </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue';

export default {
    components: {
        BButton,
    },
    methods: {
        clickBack() {
            return this.$router.back();
        },
    },
};
</script>
