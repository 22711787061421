<template>
<b-card class="mb-2">
    <b-card-text>
        <div class="table-responsive-md">
            <table class="table table-borderless">
            <tr>
                    <td>Not Road Registered?</td>
                    <td>
                        <b-form-checkbox
                            v-model="hasRegStatus"
                            switch
                            size="md"
                            @change="hasRegStatusChanged"
                        />
                    </td>
                </tr>
                <tr>
                    <td>Registration Number</td>
                    <td>
                        <b-form-input
                            v-model="registration_number"
                            placeholder="Registration Number"
                        />
                    </td>
                </tr>
                <tr>
                    <td>Vehicle Name</td>
                    <td>
                        <b-form-input
                            v-model="vehicle_name"
                            placeholder="Vehicle Name"
                        />
                    </td>
                </tr>
                <tr>
                    <td>Wheel Layout</td>
                    <td>
                        <custom-autopopulate
                            id="wheel_layout"
                            module="Wheellayouts"
                            retrieve="full_object"
                            :value="wheelId"
                            @changed="wheelSelected"
                        />
                    </td>
                </tr>
                <tr>
                    <td>Branch</td>
                    <td>
                        <custom-autopopulate
                            id="branch_name"
                            module="branches"
                            retrieve="full_object"
                            label="name"
                            :value="branchId"
                            @changed="branchSelected"
                        />
                    </td>
                </tr>
                <tr>
                    <td>Customer</td>
                    <td>
                        <b-form-input
                            :id="field.value"
                            v-model="item"
                            :state="nameState"
                            aria-describedby="client-input"
                            :value="clientID"
                            :disabled="!showClient"
                            maxlength="250"
                            trim
                            @input="resClient(showClient)"
                        />
                        <b-form-invalid-feedback id="field.value">
                            Enter at least 4 characters
                        </b-form-invalid-feedback>
                        <b-list-group v-if="filtersearches && listview">
                            <b-list-group-item
                                v-for="filtersearch in filtersearches"
                                :key="filtersearch.id"
                                @click="setclient(filtersearch.client_name, filtersearch.id)"
                            >
                                {{ filtersearch.client_name }}
                            </b-list-group-item>
                        </b-list-group>
                    </td>
                </tr>
                <tr>
                    <td>Fleet Number</td>
                    <td>
                        <b-form-input
                            v-model="fleet_number"
                            placeholder="Fleet Number"
                        />
                    </td>
                </tr>
                <tr>
                    <td>Odometer Reading</td>
                    <td>
                        <b-form-input
                            v-model="current_odometer_reading"
                            placeholder="Odometer Reading"
                        />
                    </td>
                </tr>
                <tr>
                    <td>Make</td>
                    <td>
                        <b-form-input
                            v-model="make"
                            placeholder="Make"
                        />
                    </td>
                </tr>
                <tr>
                    <td>Active Status</td>
                    <td>
                        <b-form-checkbox
                            v-model="hasActiveStatus"
                            switch
                            size="md"
                        />
                    </td>
                </tr>
                <tr>
                    <td>Car Image</td>
                    <td>
                    <b-form-file
                        v-model="image"
                        :state="Boolean(image)"
                        placeholder="Choose a file"
                        accept="image/*"
                    />
                    </td>
                </tr>
            </table>
        </div>
    </b-card-text>
    <b-card-footer>
        <span class="float-left">
            <b-button
            type="submit"
            variant="primary"
            @click="callServer"
            >
            Submit
        </b-button>
        </span>
        <GoBack />
    </b-card-footer>
</b-card>
</template>

<script>
import * as imageConversion from 'image-conversion';
import {
    BCard,
    BCardText,
    BButton,
    BCardFooter,
    BFormCheckboxGroup,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormFile,
    BListGroup,
    BListGroupItem,
    BFormInvalidFeedback,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import CustomAutopopulate from '../../common/components/CustomAutopopulate';
import GoBack from '../../common/goBack.vue';
// import Clientselect from './clientselect';

export default {
    components: {
        BCard,
        BCardText,
        BCardFooter,
        BFormInput,
        BButton,
        BFormCheckbox,
        BFormFile,
        CustomAutopopulate,
        BListGroup,
        BListGroupItem,
        BFormInvalidFeedback,
        GoBack,
    },
    directives: {
        Ripple,
    },
    // props: {
    //     id: {
    //         type: Number,
    //         required: true,
    //     },
    // },
    data() {
        return {
            // editView: false,
            fleet_number: '',
            clientID: 0,
            client_name: '',
            client: '',
            make: '',
            registration_number: '',
            vehicle_name: '',
            current_odometer_reading: '',
            hasActiveStatus: false,
            hasRegStatus: false,
            idImageIndex: false,
            editImageView: false,
            image: null,
            imageLink: '',
            showClient: '',
            item: '',
            fields_array: [],
            field: '',
            item_value: '',
            branchId: 0,
            listview: false,
            wheelId: 0,
            filtersearches: [],
        };
    },
    computed: {
        nameState() {
            return this.item.length > 3
        },
    },
    created() {
        this.module = this.propModule
            ? this.propModule
            : this.$route.params.modules;
        if (this.$route.params.id) {
            this.id = this.$route.params.id;
            this.url = `/vehicles/view/${this.id}`;
        } else {
            this.url = `/vehicles/createView`;
        }
        this.getCreateData();
    },
    methods: {
        async callServer() {
            let request = {};
            let header = null;
            header = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };
            const formdata = new FormData();
            formdata.append('client_id', this.clientID);
            formdata.append('wheel_layout_id', this.wheelId);
            formdata.append('fleet_number', this.fleet_number);
            formdata.append('registration_number', this.registration_number);
            formdata.append('vehicle_name', this.vehicle_name);
            formdata.append('current_odometer_reading', this.current_odometer_reading);
            const compressed = await imageConversion.compressAccurately(this.image, 500);
            formdata.append('image', compressed);
            formdata.append('is_active', this.hasActiveStatus);
            formdata.append('road_registered', this.hasRegStatus);
            formdata.append('make', this.make);
            request = formdata;
            this.image = null;
            this.$helper.showLoading();
            this.$axios
                .post(`vehicle/save`, request, header)
                .then(resp => {
                    this.$helper.hideLoading();
                    if (resp.data) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Vehicle Added',
                                icon: 'CheckIcon',
                                variant: 'Primary',
                            },
                        });
                        // this.editView = false;
                    }
                })
                .catch(err => {
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                });
        },
        branchSelected(value) {
            if (value) {
                this.branch_id = value.id;
                this.branch_name = value.name;
                this.showClient = value.id;
                // console.log(value);
            }
        },
        wheelSelected(value) {
            if (value) {
                this.wheelId = value.id;
                this.wheel_layout = value.name;
            }
        },
        async getCreateData() {
            this.$helper.showLoading();
            const response = await this.$axios.get(this.url).catch(error => {
                console.error(error);
                this.$helper.hideLoading();
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Oops! Something went wrong',
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                    },
                });
            });
            this.$helper.hideLoading();
            if (
                this.id
                && response.data.results
                && response.data.results.result
            ) {
                this.result = response.data.results.result;
            }
            this.fields_array = Object.values(response.data.fields);
            // this.fields_array = this.fields_array
            //     .filter(
            //         obj => ('show_in_form' in obj && obj.show_in_form)
            //             || !('show_in_form' in obj),
            //     )
            //     .map(obj => {
            //         if (obj.db_name in this.result) {
            //             // eslint-disable-next-line no-param-reassign
            //             obj.value = this.result[obj.db_name];
            //             if (obj.type === 'switch') {
            //                 const boolval = this.result[obj.db_name] === 1;
            //                 // eslint-disable-next-line no-param-reassign
            //                 obj.value = boolval;
            //                 // console.log('$#$', boolval);
            //             }
            //         }
            //         return obj;
            //     });
            // if (this.module === 'roles') {
            //     this.extra_data = {
            //         modules: response.data.results.modules,
            //         actions: response.data.results.actions,
            //         permissions: response.data.results.permissions,
            //     };
            //     this.rolePermissionId += 1;
            // }
        },
        resClient(branchId) {
            if (this.item.length > 3) {
                this.listview = true;
                this.callClient({
                    search: this.item,
                    branch_id: branchId,
                });
            }
        },
        callClient(data) {
            const params = { ...data };
            this.$helper.showLoading();
            this.$axios
                .post(`vehicles/getClientData`, params)
                .then(resp => {
                    this.$helper.hideLoading();
                    if (resp.data) {
                        // console.log(resp.data);
                        this.field = resp.data;
                        this.clientfilter();
                    }
                })
                .catch(err => {
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                });
        },
        setclient(cname, cid) {
            this.clientID = cid;
            this.item = cname;
            this.field.value = cid;
            this.listview = false;
        },
        clientfilter() {
            this.items = this.field;
            this.filtersearches = this.items.filter(item => item.client_name.toLowerCase().includes(this.item.toLowerCase()));
        },
        hasRegStatusChanged() {
            this.regStatus({
                road_registered: this.hasRegStatus,
            });
        },
        regStatus(data) {
            const params = { ...data };
            this.$helper.showLoading();
            this.$axios
                .post(`vehicles/changeRequired`, params)
                .then(resp => {
                    this.$helper.hideLoading();
                    if (resp.data) {
                        // console.log(resp.data);
                        this.field = resp.data;
                        // this.getCreateData();
                    }
                })
                .catch(err => {
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                });
        },
    },
};
</script>
<style lang="scss">
td:nth-child {
    width: 30rem;
}
.list-group {
    max-height: 150px;
    overflow-y: scroll;
}
</style>
